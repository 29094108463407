/* Declare Variables */
$primary-color: MidnightBlue;
$hover-color: #004080;
$border-radius: 4px;

/* ------------------------------------
    General Styling Rules 
---------------------------------------*/

img { 
    width: 100%; 
}

.no-line-height { 
    line-height: 0; 
}

body {
  background-color: #f0f0f0; /* Gray background for entire body */
  margin: 0;
  padding: 0;
}
/* ------------------------------------
    List and Input Styling
---------------------------------------*/

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul li {
  padding: 0 0 0 25px;
  position: relative;
}

ul li input {
  position: absolute;
  top: 5px;
  left: 0;
  opacity: 0.5;
}
/*
li.ingredient, li.direction {
  margin: -15px 0;
  font-family: "Times New Roman", Times, serif;
  font-size: 10.5px;
  text-indent: -4px;
}
*/

/* General styling for ingredients and directions */
li.ingredient, li.direction {
  margin: 10px 0; /* Add proper spacing between steps */
  font-family: "Times New Roman", Times, serif;
  font-size: 1.1rem; /* Make the font size bigger */
  line-height: 1.5; /* Improves readability */
}

li.ingredient {
    padding-left: 5px; /* Slight padding to create balance */
    text-indent: -10px; /* Pulls the text closer to the bullet */
}

/* Styling for the ordered list (directions) */
ol[itemprop="recipeInstructions"] {
    list-style-type: decimal; /* Uses numbers */
    margin-left: 40px; /* Indentation for list */
    padding-left: 0;
    counter-reset: step-counter;
}

ol[itemprop="recipeInstructions"] li {
    text-indent: -5px; /* Adjust the indent for the first line */
    padding-left: 0px; /* Ensures the rest of the lines align with the first */
    margin-bottom: 10px; /* Adds space between list items */
}

/* Styling for the unordered list (ingredients) */
ul[itemprop="ingredients"] {
    list-style-type: disc; /* Uses bullets for ingredients */
    list-style-position: inside; /* Moves the bullet closer to the text */
    margin-left: 25px; /* Indentation for list */
    padding-left: 0;
}

.ingredient-list, .direction-list {
    list-style-type: disc;
    margin-left: 10px; /* Adds indent to lists */
}

form {
  margin: 20px auto;
  max-width: 750px;
  border: 1px solid $primary-color;
  padding: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: $primary-color;
}

input[type="text"], textarea {
  width: 100%;
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: $border-radius;
  margin-bottom: 20px;
}

/* ------------------------------------
    Background Tile Styling
---------------------------------------*/

.bg-blue-tile {
    background-color: #f0f0f0;
}

@media all and (min-width: 40em) {
    .bg-blue-tile:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: " ";
        box-shadow: inset 0 0 100px 20px #f0f0f0;
        z-index: 1;
        pointer-events: none;
    }
}

/* ------------------------------------
    Max-width Utility Classes
---------------------------------------*/

.max-width-4 { max-width: 64em; }
.max-width-3 { max-width: 52em; }
.max-width-2 { max-width: 40em; }

/* ------------------------------------
    Recipe Details and Section Styling
---------------------------------------*/

.recipe-section-title {
    color: $primary-color;
    padding-bottom: 10px;
    font-size: 1.5rem; /* Adjust as necessary */
    text-align: left;
    margin-bottom: 20px;
}

div.recipe_details {
    border: 3px solid $primary-color;
    margin: 10px auto;
    max-width: 750px;
    padding: 0px 25px;
}

div.recipe_section {
    padding-bottom: 15px;
}

.logo-container {
    text-align: center; /* Centers the image and text */
    margin-top: 50px;
}

.recipe-logo {
    width: 200px; /* Adjust the size of the image if necessary */
    height: auto; /* Ensures the image maintains aspect ratio */
}

.recipe-title {
    font-family: 'Lobster', cursive; /* Replace with your chosen font */
    font-size: 2.5em; /* Adjust the font size as needed */
    color: $primary-color; /* Matches the color theme in your  */
    margin-top: 20px;
}



/* ------------------------------------
    Search Box Styling
---------------------------------------*/

.search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #f0f0f0;
    padding: 40px 20px;
    margin-top: 2vh;
    padding-bottom: 0px;
}

.search input {
    width: 100%;
    max-width: 500px;
    border-radius: 5px;
    border: 2px solid $primary-color;
    padding: 1.5rem;
    box-shadow: 0 0 14px rgba(0, 127, 255, 0.1);
    transition: box-shadow 0.3s ease;
}

.search input:focus {
    box-shadow: 0 0 10px rgba(0, 127, 255, 0.3);
}

.search input:hover {
    box-shadow: 0 0 10px rgba(0, 127, 255, 0.15);
}

#search-container {
    width: 100%; /* Take full width */
    max-width: none; /* Remove any inherited max-width */
    text-align: center;
}


/* ------------------------------------
    Search Results Container
---------------------------------------*/

#results-container {
    padding-top: 0px;
    margin-top: 10px;
    color: $primary-color;
    text-align: left;
    width: 90%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

#results-container div {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    font-size: 1.2rem;
}

#results-container div a {
    color: $primary-color;
    text-decoration: none;
}

#results-container div a:hover {
    text-decoration: underline;
    color: $primary-color;
}

#results-container a {
    color: $primary-color; /* Uses the SCSS variable for color */
}

/* ------------------------------------
    Form Styling
---------------------------------------*/

input[type="submit"], input[type="button"] {
    padding: 10px 20px;
    background-color: $primary-color;
    color: white;
    border: none;
    border-radius: $border-radius;
    cursor: pointer;
}

input[type="submit"]:hover, input[type="button"]:hover {
    background-color: $hover-color;
}

/* ------------------------------------
    Post Styling
---------------------------------------*/

.hero {
    height: 150px;
}

.post h1 {
    margin-top: 6rem;
}

.post ol, .post ul {}

.post li {
    margin-bottom: 1rem;
    padding: 0 0 0 1rem;
}

.post li:before {
    content: "- ";
    position: absolute;
    left: 0;
    color: #f0f0f0;
}

/* ------------------------------------
    Logo and Image Styling
---------------------------------------*/

a .image {
    transition: .2s ease all;
    opacity: 1;
}

a:hover .image {
    opacity: 0.2;
}

/* ------------------------------------
    Button Styling
---------------------------------------*/

.button {
    background-color: $primary-color;
    border: none;
    color: white;
    padding: 12px 24px;
    text-align: center;
    font-size: 1.1rem;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: $hover-color;
}

/* General button styling */
.custom-recipe-btn {
    background-color: $primary-color;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 12px 24px;
    font-size: 1.2rem;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.2s;
    display: inline-block;
    position: fixed;
    bottom: 40px;
    right: 40px;
}

.custom-recipe-btn:hover {
    background-color: #0056b3; /* Slightly lighter blue */
    transform: translateY(-2px); /* Small lift effect */
}

/* Larger screens */
@media screen and (min-width: 1024px) {
    .custom-recipe-btn {
        font-size: 1.0rem;
        padding: 10px 20px;
    }
    .recipe_section[itemprop="description"] {
        min-width: 600px;
    }
    .recipe_details {
        border: 3px solid $primary-color;
        margin: 10px auto;
        padding: 15px 25px;
        max-width: 750px;
    }
    .home-icon {
        width: 30px;
        fill: $primary-color; /* Uses the SCSS variable for color */
        transition: fill 0.3s ease; /* Optional: adds smooth transition */
        position: absolute;
        top: 10px; /* Move the icon down */
        left: 20px; /* Adjust to make sure it's visible */
    }
}

/* Smaller screens (like mobile) */
@media screen and (max-width: 767px) {
    .recipe_details {
        border: none !important; /* Removes blue border on smaller screens */
        padding: 0;
        max-width: 100%; /* Adjust to fit full screen */
        margin: 0 auto;
    }
    .recipe_section[itemprop="description"] {
        min-width: 90%; /* Make sure the text flows */
    }
    .button-container {
        text-align: right;
    }    
    .custom-recipe-btn {
        font-size: 0.8rem;
        padding: 8px 16px;
        bottom: 20px; /* Bring buttons up a bit */
        right: 20px;
    }
    input[type="submit"], input[type="button"] {
        font-weight: bold;
        font-size: 0.8rem; /* Adjust the font size */
        margin: 0 10px; /* Add margin to create space between buttons */
        padding: 6px 10px;
    }
    form {
        border: none; /* Remove blue border on iPhone during editing */
        padding: 0px;
        margin: 0; /* Maximize available space */
        max-width: 100%; /* Full width on iPhone */
    }
    .home-icon {
        width: 20px; /* Adjust the size */
        position: absolute;
        fill: $primary-color !important;
        top: 20px; /* Adjust position on mobile */
        left: 10px;
    }
}
/* ------------------------------------
    Typography Styling
---------------------------------------*/

h1, h2, h3, h4, h5, h6 {
    font-family: 'Inter UI', Helvetica, sans-serif;
    font-weight: 600;
    margin-top: 1em;
    margin-bottom: .5em;
    color: $primary-color;
}

p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
}

a {
    color: $primary-color;
    font-weight: 500;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
    color: $hover-color;
}

/* ------------------------------------
    Container Styling
---------------------------------------*/

.container {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    box-sizing: border-box;
}

.print-button-container {
    width: 18px;
    height: 18px;
    margin-top: 10px; /* Increase the margin to move it down */
    text-align: right; /* Keep it aligned to the right */
}

#print-button {
  width: 20px;
  height: 20px;
  fill: $primary-color;
  transition: fill 0.3s ease;
  position: relative; /* Ensure it can move within the container */
  top: 5px; /* Moves the print button down slightly */
}

#print-button:hover {
  fill: $hover-color; /* Slightly darker blue on hover */
}

/* Responsiveness */
@media screen and (max-width: 767px) {
  #print-button {
    width: 15px;
    height: 15px;
  }
}

@media screen and (min-width: 1024px) {
  #print-button {
    width: 18px;
    height: 18px;
  }
}
